export default [
  {
    label: 'Description',
    field: 'description',
  },
  {
    label: 'Quarter',
    field: 'quarter',
  },
  {
    label: 'Year',
    field: 'year',
  },
  {
    label: 'Published',
    field: 'status',
  },
  {
    label: 'Modified',
    field: 'updatedAt',
  },
  {
    label: 'Action',
    field: 'action',
  },
]

<template>
  <div>
    <b-row>
      <b-col>
        <div class="d-flex  justify-content-end flex-wrap mb-2">
          <b-button class="mt-auto" type="button" variant="primary" @click="create">
            <feather-icon icon="PlusIcon" size="14" />
            <span> Create card</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <AppTable
      :rows="rows"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: journey.limit,
        count: journey.count,
        page: journey.page,
      }"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #default="{ column, row, formattedRow }">
        <span v-if="column.field === 'description'" class="description">
          {{ row.description }}
        </span>

        <span v-else-if="column.field === 'status'" class="flex-center-align">
          <b-badge :variant="row.status.theme" class="text-white">
            {{ row.status.text }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="column.label === 'Action'">
          <span>
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
              </template>

              <b-dropdown-item @click="edit(row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteCard(row)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <!-- default render cell -->
        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { format } from 'date-fns'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import columns from './config/tableConfig'
import AppTable from '@/components/AppTable.vue'

export default {
  name: 'JourneyView',
  components: {
    AppTable,
    BButton,
    BRow,
    BCol,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      columns,
    }
  },

  computed: {
    ...mapGetters({
      journey: 'journey/journey',
    }),

    rows() {
      return this.transformCardData(this.journey?.value || [])
    },
  },

  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchJourney: 'journey/fetchJourney',
      removeCard: 'journey/removeCard',
    }),

    formatDate(timestamp) {
      return format(new Date(timestamp), 'dd.MM.yyyy HH:mm:ss')
    },

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })
      
      this.getJourney(query)
    },

    create() {
      this.$router.push({ path: '/journey/create' })
    },

    edit({ id }) {
      this.$router.push({ path: `/journey/edit/${id}` })
    },

    deleteCard(question) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, delete!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (!result.isConfirmed) return

        this.removeJourneyCard(question.id)
      })
    },

    removeJourneyCard(memberId) {
      this.removeCard(memberId)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Card has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(this.checkErrors)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.getJourney(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.getJourney(query)
    },

    createQueryConfig(config) {
      return {
        page: this.journey.page,
        limit: this.journey.limit,
        search: this.searchTerm,

        ...config,
      }
    },

    getJourney(query = {}) {
      return this.waitRequest(() => {
        return this.fetchJourney(query).catch(this.checkErrorsAlert)
      })
    },

    transformCardData(rows) {
      const theme = {
        active: 'success',
        'not active': 'info',
      }

      return rows.map(rowData => {
        const statusText = rowData.isPublish ? 'active' : 'not active'
        const status = {
          text: statusText,
          theme: theme[statusText],
        }

        const updatedAt = this.formatDate(rowData.updatedAt)

        return {
          ...rowData,
          status,
          updatedAt,
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';

.description {
  width: 400px;
}
</style>
